// import logo from './logo.svg';
// import './App.css';

// import {  Authenticator, 
//           Flex, 
//           View, 
//           AmplifyProvider,
//           Grid,
//           Image,
//            useTheme   } from '@aws-amplify/ui-react';
// import '@aws-amplify/ui-react/styles.css';

// import { Header } from "./Header";
// import { Footer } from "./Footer";
// import { SignInHeader } from "./SignInHeader";
// import { SignInFooter } from "./SignInFooter";
// import { Home } from "./Home";
// import { Login } from "./Login";
// import "./styles.css";

// // const components = {
// //   Header,
// //   SignIn: {
// //     Header: SignInHeader,
// //     Footer: SignInFooter
// //   },
// //   Footer
// // };

// // // Step 1: Create a new Theme with your custom values
// // const theme = {
// //   name: 'my-theme',
// //   tokens: {
// //     colors: {
// //       font: {
// //         primary: { value: '#008080' },
// //         // ...
// //       },
// //     },
// //   },
// // };


// function App() {
//   return (
//     <Authenticator  >

//       {({ signOut, user }) => (
//         <AmplifyProvider >

//         if (user) {
//            <main>
//            <h1>Hello {user.username}</h1>
//            <button onClick={signOut}>Sign out</button>
//          </main>

//         }
//           return <Login />;

//           {/* <div className="App">
//              <p>
//               Hey {user.username}, welcome to my channel, with auth!
//             </p>
//             <button onClick={signOut}>Sign out</button>

//             <Flex direction={{ base: 'column', large: 'row' }}>
//               <View width="20%" backgroundColor={{ base: 'orange', large: 'yellow' }}>
//               Hello
//             </View>
//             <View width="20%" backgroundColor={['orange', 'orange', 'orange', 'yellow']}>
//               there!
//              </View>
//             </Flex>;

//             <header className="App-header">
//               <img src={logo} className="App-logo" alt="logo" />
//                 <p>
//                   Edit <code>src/App.js</code> and save to reload.
//                 </p>
//             <a
//               className="App-link"
//               href="https://reactjs.org"
//               target="_blank"
//               rel="noopener noreferrer"
//             >
//             Learn React
//             </a>
//             </header>
//           </div> */}
//         </AmplifyProvider>
//       )}

//     </Authenticator>
//   );
// }

//  export default App;

// https://stackoverflow.com/questions/70036160/amplifysignout-is-not-exported-from-aws-amplify-ui-react


// export default App;
// function App() {
//   return (
//     <div className="App">
      
//      <AmplifySignOut />
//     </div>
//   );
// }

// export default withAuthenticator(App);



// import { useAuthenticator } from "@aws-amplify/ui-react";
// import { Amplify } from "aws-amplify";

// import { Home } from "./Home";
// import { Login } from "./Login";
// import "./styles.css";

// import awsExports from "./aws-exports";
// Amplify.configure(awsExports);

// export default function App() {
//   const { user } = useAuthenticator();

  // if (user) {
  //   return <Home />;
  // }

  // return <Login />;
// }

import { Amplify } from "aws-amplify";
import { Authenticator, withAuthenticator, useTheme, View, Image, Text, useAuthenticator, Heading, Button, AmplifyAuthenticator, AmplifySignIn } from "@aws-amplify/ui-react";
import { Header } from "./Header";
import { Footer } from "./Footer";
import { SignInHeader } from "./SignInHeader";
import { SignInFooter } from "./SignInFooter";
import "./styles.css";

import awsExports from "./aws-exports";
import background from "./curology-ycEKahEaO5U-unsplash.jpg";
import clean_icon from "./cleaning-svgrepo-com.jpg"

Amplify.configure(awsExports);

/*

function App() {
  return (
    <Authenticator>
      <h1>Welcome</h1>
      {({ signOut, user }) => (
        <div className="App" slot="sign-in" style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          backgroundImage: `url(${background})`,
          backgroundSize: '1800px 1800px'
        }}>
          <p>
            Hey {user.username}, welcome to my channel, with auth!
          </p>
          <button onClick={signOut}>Sign out</button>
        </div>
      )}
    </Authenticator>
  );
}

export default App;

*/

const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
         <h1>Welcome</h1>
         <h2>Cleaner2u.net</h2>
        {/* <Image
          alt="cleaning_logo"
          src={clean_icon}
        /> */}
      </View>
    );
  },

  Footer() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Text color={`${tokens.colors.neutral['80']}`}>
          &copy; All Rights Reserved
        </Text>
      </View>
    );
  },

  SignIn: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Sign in to your account
        </Heading>
      );
    },
    Footer() {
      const { toResetPassword } = useAuthenticator();

      return (
        <View textAlign="center">
          <Button
            fontWeight="normal"
            onClick={toResetPassword}
            size="small"
            variation="link"
          >
            Reset Password
          </Button>
        </View>
      );
    },
  },

  SignUp: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Create a new account
        </Heading>
      );
    },
    Footer() {
      const { toSignIn } = useAuthenticator();

      return (
        <View textAlign="center">
          <Button
            fontWeight="normal"
            onClick={toSignIn}
            size="small"
            variation="link"
          >
            Back to Sign In
          </Button>
        </View>
      );
    },
  },
  ConfirmSignUp: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    Footer() {
      return <Text>Footer Information</Text>;
    },
  },
};

export default function App() {
  return (
    <Authenticator components={components}>
      {({ signOut, user }) => 

        <div>
          <h1>Welcome {user.username}</h1>
        <button onClick={signOut}>Sign out</button>
        </div>
        }
    </Authenticator>
  );
}


// export function App({ signOut, user }) {
//   return (
   
//     <main>
//       <h1>  Welcome</h1>
//       <button onClick={signOut}>  Sign out</button>
//     </main>
//   );
// }

// export default withAuthenticator( App, {
//   components: {
//     Header,
//     SignIn: {
//       Header: SignInHeader,
//       Footer: SignInFooter
//     },
//     Footer
//   }
// });

 //  <h1>Hello {user.username}</h1>


//  <div className="App" slot="sign-in" style={{
//   display: 'flex',
//   justifyContent: 'center',
//   alignItems: 'center',
//   height: '100vh',
//   backgroundImage: `url(${background})`,
//   backgroundSize: '1800px 1800px'
// }}></div>